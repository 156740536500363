.Work{
    margin: 160px auto 0px auto;
    width: 60%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.Work h1{
    margin: 5px 0px;
    font-size: 52px;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.Work h3{
    font-family: 'General Sans';
    font-weight: 100;
    font-size: 12px;
    margin: 5px 0px;
}
.Work-projects{
    width: 100%;
    margin: 100px 0px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
a{
    text-decoration:none;
    color:white;
}
.Work-project{
    background-image: linear-gradient(rgba(255, 255, 255, .2), rgba(255, 255, 255, 0));
    width: 100%;
    height: 200px;
    border-radius: 10px;
    padding: .5px;
    margin: 10px 0px;
    transition: ease-in-out .225s;
}
.Work-project:hover{
    transform: scale(1.02) !important;
    box-shadow: rgba(255, 255, 255, 0.175) 0px 0px 10px 1px;
    cursor: pointer;
}
.Work-project-inside{
    background-color: rgb(19, 19, 19);
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.Work-project-inside-information{
    /* border: solid red 1px; */
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'General Sans';
}
.Work-project-inside-information h2{
    font-size: 21px;
    margin: 2px 0px;
    text-align: center;
}
.Work-project-inside-information h4{
    font-size: 15px;
    margin: 2px 0px;
    text-align: center;
}
.Work-project-inside-information p{
    font-size: 14px;
    color: rgb(213, 213, 213);
    text-align: center;
    margin: 2px 0px;
}
.Work-project-inside-image{
    /* border: solid green 1px; */
    width: 50%;
    height: 100%;
    border-radius: 0px 10px 10px 0px;
    background-color: #252d3f;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
.Work-project-inside-image img{
    /* width: 260px; */
    width: 85%;
    max-width: 310px;
    margin-top: 24px;
    border-radius: 7px 7px 0px 0px;
    box-shadow: rgba(0, 0, 0, 0.333) 0px 0px 10px 1px;
}

@media only screen and (max-width: 600px) {
    .Work{
        width:80%;
    }   
    .Work-project{
        height: 300px;
    }
    .Work-project-inside {
      flex-direction: column-reverse;
    }
    .Work-project-inside-information{
        width: 100%;
    }
    .Work-project-inside-information h2{
        font-size: 18px;
    }
    .Work-project-inside-information h4{
        font-size: 12px;
        width: 70%;
        font-weight: 100;
    }
    .Work-project-inside-image{
        width: 100%;
        border-radius: 10px 10px 0px 0px;
    }
  }
