/* @import url('https://fonts.cdnfonts.com/css/lumen'); */
@import url('https://fonts.cdnfonts.com/css/handwriting');
.Home{
    margin: 160px auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.Home h1{
/*   font-family:'lumen'; */
    font-family:'Handwriting', sans-serif;
}
.Home p{
  font-family:'Handwriting',"General Sans";
}
.gifball{
  z-index: -10;
  top: 0;
  width: 100px;
}
.ReadMeImg{
    display:block;
    width:85%;
    border-radius:15px;
}
.ReadMeImgMobile{
    display:none;
    width:90%;
}
.backgroundpattern{
    position: absolute;
    top: 100px;
    left: 0px;
    z-index: -100;
/*     width:95%; */
}
@media only screen and (max-width: 600px) {
  .ReadMeImg{
    display:none;
}
.ReadMeImgMobile{
    display:block;
}
.backgroundpattern{
    width:95%;
}
}

/************/
.resume-button {
    margin: 30px 0px;
    text-decoration: none;
    position: relative;
    border: none;
    font-size: 14px;
    font-family: inherit;
    cursor: pointer;
    color: #fff;
    width: 7.5em;
    height: 2.7em;
    line-height: 2em;
    text-align: center;
    background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
    background-size: 300%;
    border-radius: 30px;
    z-index: 1;
  }
  
  .resume-button:hover {
    animation: ani 8s linear infinite;
    border: none;
  }
  
  @keyframes ani {
    0% {
      background-position: 0%;
    }
  
    100% {
      background-position: 400%;
    }
  }
  
  .resume-button:before {
    content: "";
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
    background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
    background-size: 400%;
    border-radius: 35px;
    transition: 1s;
  }
  
  .resume-button:hover::before {
    filter: blur(20px);
  }
  
 .resume-button:active {
    background: linear-gradient(32deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  }
  
