footer{
    display: flex;
    margin: 5px auto 0px auto;
    width: 100%;
    height: 30px;
    /* background-color: rgb(255, 0, 0); */
    color: rgb(255, 255, 255);
    align-items: center;
    justify-content: center;
    padding: 20px;
    font-size: 14px;
    position: relative;
    bottom: 0%;
    /* border: solid red 1px; */
}
footer h2{
    font-size: 11px;
    font-family: "General Sans";
    color: rgb(208, 208, 208);
}