*{
    margin: 0;
    box-sizing:border-box;
    /* cursor: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.583333' y='0.583333' width='26.8333' height='26.8333' rx='13.4167' stroke='white' stroke-opacity='0.2' stroke-width='1.16667'/%3E%3Cpath d='M14.0013 4.66666L14.3522 11.2221L16.3224 4.95988L15.0321 11.3966L18.4977 5.82113L15.6471 11.7347L20.3904 7.19628L16.1587 12.2152L21.8817 8.99894L16.5348 12.8078L22.8778 11.1158L16.7517 13.4753L23.3162 13.4139L16.7958 14.1758L23.1693 15.7489L16.6643 14.8652L22.4464 17.9739L16.3654 15.5003L21.1928 19.9493L15.918 16.0411L19.4873 21.5508L15.3502 16.4536L17.4371 22.6779L14.6976 16.712L15.1711 23.2597L14.0013 16.8L12.8315 23.2597L13.305 16.712L10.5655 22.6779L12.6524 16.4536L8.51531 21.5508L12.0846 16.0411L6.80985 19.9493L11.6372 15.5003L5.55625 17.9739L11.3383 14.8652L4.83329 15.7489L11.2068 14.1758L4.68639 13.4139L11.2509 13.4753L5.12477 11.1158L11.4678 12.8078L6.12091 8.99894L11.8439 12.2152L7.6122 7.19628L12.3555 11.7347L9.50493 5.82113L12.9706 11.3966L11.6802 4.95988L13.6504 11.2221L14.0013 4.66666Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.3242 21.0805L11.3242 6.91956L21.1121 17.1934L15.1664 17.1934L15.0389 17.3031L11.3242 21.0805Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.168 8.95522L12.168 18.8679L14.6732 16.3286L14.8082 16.2054L19.0575 16.2127L12.168 8.95522Z' fill='black'/%3E%3C/svg%3E"), auto; */
}
body{
    font-family: 'Segoe UI';
    background-color: #0c0c0c;
    color: white;
    user-select:none;
}

::-webkit-scrollbar {
    /* z-index: 100; */
    background-repeat: no-repeat;
    backdrop-filter: blue(20px);
    width: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: rgb(57, 57, 57);
    width: 20px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgb(94, 94, 94);
  }
  .Home,.Work,.About{
    min-height:calc(100vh - 380px);;
  }
  a{
    text-decoration: none;
  }
